// src/components/LeftSidebar/LeftSidebar.jsx

import React from 'react';
import HotTopics from './HotTopics/HotTopics.jsx';  // 상대 경로 확인
import UserInfo from './UserInfo/UserInfo.jsx';      // 상대 경로 확인
import './LeftSidebar.css';

const LeftSidebar = () => {
  return (
    <div className="left-sidebar">
      <HotTopics />
      <UserInfo />
    </div>
  );
};

export default LeftSidebar;
