// src/components/observer/presentationpanel/presentationpanel.jsx
import React from 'react';
import VideoChat from '../../VideoChat/VideoChat';
import './PresentationPanel.css';

const PresentationPanel = ({ roomName }) => {
  return (
    <div className="presentation-panel">
      <div className="presentation-content">
        {/* 토론자 1 화면 */}
        <VideoChat roomName={roomName} team="red" role="observer" />
      </div>
      <div className="presentation-content2">
        <div className="presentation-content2-1">
          {/* 토론자 2 화면 */}
          <VideoChat roomName={roomName} team="blue" role="observer" />
        </div>
        <div>
          <div className="detail">
            <img
              src="/red.png"
              alt="토론자 이미지"
              className="debater-image1"
            />
            03 : 00
          </div>
          <div className="detail">
            <img src="/mute.png" alt="음속어" className="mute-image" />
            <img
              src="/blue.png"
              alt="토론자 이미지"
              className="debater-image2"
            />
            03 : 00
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationPanel;
