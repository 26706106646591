import { useState, useCallback } from 'react';
import useSocket from './useSocket.js';
import useMediasoupDevice from './useMediasoupDevice.js';
import useProducer from './useProducer.js';
import useConsumer from './useConsumer.js';

const useMediasoupClient = (roomNumber, role, stream) => {
  const [consumerTransports, setConsumerTransports] = useState([]);
  const socket = useSocket(() => joinRoom(), roomNumber, role);
  const { device, initializeDevice } = useMediasoupDevice();
  const { signalNewConsumerTransport } = useConsumer(device, socket, consumerTransports, setConsumerTransports);

  const getProducers = useCallback(() => {
    socket.emit('getProducers', (producerIds) => {
      producerIds.forEach((id) => signalNewConsumerTransport(id));
    });
  }, [socket, signalNewConsumerTransport]);

  const { createSendTransport } = useProducer(device, stream, socket, getProducers);

  const joinRoom = useCallback(() => {
    initializeDevice().then((newDevice) => {
      if (role !== 'observer') {
        createSendTransport(newDevice);
      }
    });
  }, [initializeDevice, role, createSendTransport]);

  return {
    joinRoom,
    signalNewConsumerTransport,
  };
};

export default useMediasoupClient;
