import React from 'react';
import './ChatPanel.css';

const ChatPanel = () => {
  return (
    <div className="chat-panel">
      <div className="chat-messages">
        {/* 채팅 메시지 목록이 여기에 표시됩니다 */}
      </div>
      <div className="chat-input-container">
        <input type="text" className="chat-input" placeholder="메시지를 입력하세요..." />
        <button className="send-button">전송</button>
      </div>
    </div>
  );
};

export default ChatPanel;
