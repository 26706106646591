// src/components/MainContent/MainContent.jsx
import React from 'react';
import ParticipantScreen from './ParticipantScreen/ParticipantScreen.jsx';
import RecordingControls from './RecordingControls/RecordingControls.jsx';
import MyFiles from './MyFiles/MyFiles.jsx';
import Controls from './Controls/Controls.jsx';
import './MainContent.css';

const MainContent = ({ roomNumber, team, role }) => {
  return (
    <div className="main-content">
      {/* ParticipantScreen에 roomNumber 전달 */}
      <ParticipantScreen roomNumber={roomNumber} team={team} role={role} />
      <div className="bottom-section">
        <div className="left-controls">
          <Controls />
          <RecordingControls />
        </div>
        <div className="my-files-container">
          <MyFiles />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
