import React from 'react';
import './RoomInfoPanel.css';

const RoomInfoPanel = () => {
  return (
    <div className="room-info-panel">
      <div className="room-info-header">방 정보</div>
      <div className="room-details">
        <p><strong>방 이름:</strong> 토론방 1</p>
        <p><strong>주제:</strong> 환경 보호의 필요성</p>
        <p><strong>참가자:</strong> 5명</p>
      </div>
    </div>
  );
};

export default RoomInfoPanel;
