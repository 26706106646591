import { useCallback } from 'react';

const useConsumer = (device, socket, consumerTransports, setConsumerTransports) => {
  // connectRecvTransport를 상단에 정의하여 사용 순서에 따른 문제 해결
  const connectRecvTransport = useCallback(
    async (consumerTransport, remoteProducerId, serverConsumerTransportId) => {
      await socket.emit(
        'consume',
        {
          rtpCapabilities: device.rtpCapabilities,
          remoteProducerId,
          serverConsumerTransportId,
        },
        async ({ params }) => {
          if (params.error) {
            console.error('Cannot consume:', params.error);
            return;
          }

          try {
            const consumer = await consumerTransport.consume({
              id: params.id,
              producerId: params.producerId,
              kind: params.kind,
              rtpParameters: params.rtpParameters,
            });

            setConsumerTransports((prev) => [
              ...prev,
              { consumerTransport, consumer, producerId: remoteProducerId },
            ]);

            // 화면에 비디오 추가
            const newElem = document.createElement('div');
            newElem.setAttribute('id', `td-${remoteProducerId}`);
            newElem.innerHTML = `<${params.kind} id="${remoteProducerId}" autoplay></${params.kind}>`;
            document.querySelector('.remote-videos').appendChild(newElem);

            document.getElementById(remoteProducerId).srcObject = new MediaStream([consumer.track]);
            socket.emit('consumer-resume', { serverConsumerId: params.serverConsumerId });
          } catch (error) {
            console.error('Error consuming stream:', error);
          }
        }
      );
    },
    [device, socket, setConsumerTransports]
  );

  const signalNewConsumerTransport = useCallback(
    (remoteProducerId) => {
      if (consumerTransports.some((transport) => transport.producerId === remoteProducerId)) return;

      socket.emit('createWebRtcTransport', { consumer: true }, ({ params }) => {
        if (params.error) {
          console.error(params.error);
          return;
        }

        const consumerTransport = device.createRecvTransport(params);

        consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
          try {
            await socket.emit('transport-recv-connect', {
              dtlsParameters,
              serverConsumerTransportId: params.id,
            });
            callback();
          } catch (error) {
            errback(error);
          }
        });

        connectRecvTransport(consumerTransport, remoteProducerId, params.id);
      });
    },
    [consumerTransports, device, socket, connectRecvTransport]
  );

  return {
    signalNewConsumerTransport,
  };
};

export default useConsumer;
