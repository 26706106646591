import React, { useState } from 'react';
import './FileUpload.css';

const FileUpload = () => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Upload successful:", data); // 로그 추가
        setResponseMessage(`File uploaded successfully: ${data.url}`);
        setFileName('');
        setFile(null);
      } else {
        const errorText = await response.text();
        throw new Error(`File upload failed: ${errorText}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setResponseMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="file-upload">
      <input
        type="file"
        id="file-input"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="file-input" className="upload-button">
        Upload File
      </label>
      {fileName && <div className="file-name">{fileName}</div>}
      <button onClick={handleFileUpload} disabled={!file}>
        Submit
      </button>
      {responseMessage && <div className="response-message">{responseMessage}</div>}
    </div>
  );
};

export default FileUpload;
