import './HotTopics.css';

const topics = [
  'Climate Change',
  'Tech Innovations',
  'Global Politics',
  'Health and Wellness',
  'Economic Trends',
  'Entertainment News',
  'Sports Highlights',
];

const HotTopics = () => {
  return (
    <div className="hot-topics">
      <h3>Hot Topics</h3>
      <ul>
        {topics.map((topic, index) => (
          <li key={index} className="topic-item">
            <span className="topic-rank">{index + 1}</span>
            {topic}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HotTopics;