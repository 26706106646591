import React, { useState } from 'react';
import WhiteBoard from '../WhiteBoard/WhiteBoard';
import './UserList.css';

const UserList = () => {
  const [isWhiteBoardOpen, setIsWhiteBoardOpen] = useState(false);

  const openWhiteBoard = () => {
    setIsWhiteBoardOpen(true);
  };

  const closeWhiteBoard = () => {
    setIsWhiteBoardOpen(false);
  };

  return (
    <div className="user-list">
      <div className="user">
        <div className="profile-pic"></div>
        <span className="username">유저1</span>
      </div>
      <div className="user">
        <div className="profile-pic"></div>
        <span className="username">유저2</span>
      </div>
      <div className="user">
        <div className="profile-pic"></div>
        <span className="username">유저3</span>
      </div>
      <button className="add-user-button" onClick={openWhiteBoard}>
        보드
      </button>

      {isWhiteBoardOpen && <WhiteBoard onClose={closeWhiteBoard} />}
    </div>
  );
};

export default UserList;
