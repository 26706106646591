import { useEffect, useState } from 'react';
import { mediaSocket as socket } from '../utils/socket.js';

const useSocket = (onConnectionSuccess, roomNumber, role) => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!isConnected) {
      // 소켓 연결 및 초기화
      socket.on('connection-success', ({ socketId }) => {
        console.log('Connected to server with socket ID:', socketId);
        setIsConnected(true);
        if (onConnectionSuccess) onConnectionSuccess();
      });

      socket.emit('joinRoom', { roomNumber, role }, (response) => {
        if (response.error) {
          console.error('Join room error:', response.error);
        } else {
          console.log('Join room success:', response);
        }
      });

      return () => {
        // 소켓 이벤트 리스너 정리만 하고, 소켓 연결은 끊지 않음
        socket.off('connection-success');
      };
    }
  }, [onConnectionSuccess, roomNumber, role, isConnected]);

  return socket;
};

export default useSocket;
