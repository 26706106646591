import React from 'react';
import './RecordPanel.css';

const RecordPanel = () => {
  return (
    <div className="record-panel">
      <div className="record-header">기록</div>
      <div className="record-content">
        {/* 기록 목록이 여기에 표시됩니다 */}
      </div>
      <div className="record-input-container">
        <input type="text" className="record-input" placeholder="새로운 기록을 입력하세요..." />
        <button className="add-record-button">추가</button>
      </div>
    </div>
  );
};

export default RecordPanel;
