import React from 'react';
import './UserInfo.css';

const UserInfo = () => {
  return (
    <div className="user-info">
      <div className="user-profile">
        <div className="user-avatar"></div>
        <div className="user-details">
          <div className="user-nickname">Nickname</div>
          <div className="user-level">LV.1</div>
        </div>
      </div>
      <div className="user-stats">
        <div>Balance: 10</div>
        <div>Coupons: 12</div>
        <div>No : 20</div>
        <div>Party : Jungle</div>
        <div>Sparta</div>
      </div>
      <div className="user-actions">
        <button className="action-button">START</button>
        <button className="action-button">NEXT</button>
        <button className="action-button">STOP</button>
        <button className="action-button">EXIT</button>
      </div>
    </div>
  );
};

export default UserInfo;
