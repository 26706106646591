// src/Layers.jsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home.jsx';
import Room from './components/Room/Room.js';
import Login from './components/Login/Login.jsx';
import Observer from './components/Observer/Observer.jsx';
import Signup from './components/Signup/Signup.jsx';

const Layers = () => {
  return (
    <Router>
      <Routes>
        {/* 메인 페이지 라우트 */}
        <Route path="/" element={<Home />} />

        {/* 참여자와 관전자 라우트 */}
        <Route path="/room/:roomNumber" element={<Room role="participant" />} />
        <Route path="/observer/:roomNumber" element={<Observer role="observer" />} />

        {/* 로그인 및 회원가입 페이지 라우트 */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </Router>
  );
};

export default Layers;
