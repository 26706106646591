import { useCallback } from 'react';

const useProducer = (device, stream, socket, getProducers) => {
  // connectSendTransport 상단에 정의
  const connectSendTransport = useCallback(
    async (newProducerTransport) => {
      try {
        const audioProducer = await newProducerTransport.produce({ track: stream.getAudioTracks()[0] });
        const videoProducer = await newProducerTransport.produce({ track: stream.getVideoTracks()[0] });

        audioProducer.on('trackended', () => console.log('Audio track ended'));
        videoProducer.on('trackended', () => console.log('Video track ended'));
      } catch (error) {
        console.error('Error connecting send transport:', error);
      }
    },
    [stream]
  );

  const createSendTransport = useCallback(
    (newDevice) => {
      socket.emit('createWebRtcTransport', { consumer: false }, async ({ params }) => {
        if (params.error) {
          console.error(params.error);
          return;
        }

        const newProducerTransport = await newDevice.createSendTransport(params);

        newProducerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
          try {
            await socket.emit('transport-connect', { dtlsParameters });
            callback();
          } catch (error) {
            errback(error);
          }
        });

        newProducerTransport.on('produce', async (parameters, callback, errback) => {
          try {
            await socket.emit(
              'transport-produce',
              {
                kind: parameters.kind,
                rtpParameters: parameters.rtpParameters,
                appData: parameters.appData,
              },
              ({ id, producersExist }) => {
                callback({ id });
                if (producersExist) {
                  // `getProducers`를 인자로 받아 호출
                  getProducers();
                }
              }
            );
          } catch (error) {
            errback(error);
          }
        });

        connectSendTransport(newProducerTransport);
      });
    },
    [socket, connectSendTransport, getProducers]
  );

  return {
    createSendTransport,
  };
};

export default useProducer;
