import React, { useEffect, useRef } from 'react';
import './VideoChat.css';
import useMediasoupClient from '../../hooks/useMediasoupClient';
import { mediaSocket as socket } from '../../utils/socket.js';

const VideoChat = ({ roomNumber, role }) => {
  const localVideoRef = useRef();
  const videoContainerRef = useRef();

  // roomNumber와 role의 값을 확인하기 위해 로그 출력
  useEffect(() => {
    console.log('Room Number:', roomNumber);
    console.log('Role:', role);
  }, [roomNumber, role]);

  const { joinRoom, signalNewConsumerTransport } = useMediasoupClient(roomNumber, role, localVideoRef.current?.srcObject);

  useEffect(() => {
    joinRoom();

    const handleNewProducer = ({ producerId }) => {
      signalNewConsumerTransport(producerId);
    };

    socket.on('new-producer', handleNewProducer);

    return () => {
      socket.off('new-producer', handleNewProducer);
    };
  }, [joinRoom, signalNewConsumerTransport]);

  return (
    <div className="video-chat">
      {role !== 'observer' && <video ref={localVideoRef} autoPlay muted className="local-video" />}
      <div ref={videoContainerRef} className="remote-videos"></div>
    </div>
  );
};

export default VideoChat;
