// src/App.js
import React from 'react';
import Layers from './Layers.js';

function App() {
  return (
    <div className="App">
      <Layers />
    </div>
  );
}

export default App;
