import React, { useState } from 'react';
import './MicButton.css';

const MicButton = () => {
  const [isMicOn, setIsMicOn] = useState(false);
  const [localStream, setLocalStream] = useState(null);

  // 마이크 시작 (on)
  const handleStart = async () => {
    try {
      // 내 음성 스트림을 가져오기
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setLocalStream(stream); // localStream 상태 설정
      setIsMicOn(true); // 마이크 켜짐 상태
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  // 마이크 중지 (off)
  const handleStop = () => {
    if (localStream) {
      // 스트림을 종료하여 마이크를 끄기
      localStream.getTracks().forEach(track => track.stop());
      setLocalStream(null); // localStream 상태 초기화
    }
    setIsMicOn(false); // 마이크 꺼짐 상태
  };

  // 마이크 상태 변경 (toggle)
  const toggleMic = () => {
    if (isMicOn) {
      handleStop();
    } else {
      handleStart();
    }
  };

  return (
    <div
      className={`mic-button ${isMicOn ? 'mic-on' : 'mic-off'}`}
      onClick={toggleMic}
    >
      {isMicOn ? 'Mic On 🔊' : 'Mic Off 🔇'}
    </div>
  );
};

export default MicButton;
