import React, { useState } from 'react';
import './WhiteBoard.css';

const WhiteBoard = ({ onClose }) => {
  const [content, setContent] = useState('');
  const [displaydContent, setDisplaydContent] = useState('');

  const handleInputChange = (e) => {
    setContent(e.target.value);
  };

  const handleAddContent = () => {
    setDisplaydContent(content);
    setContent('');
  };

  return (
    <div className="whiteboard-overlay">
      <div className="whiteboard">
        <button className="close-button" onClick={onClose}>
          닫기
        </button>
        <div className="board-content">
          <p>{displaydContent}</p>
        </div>
        <div className="input-area">
          <input
            type="text"
            value={content}
            onChange={handleInputChange}
            placeholder="내용을 입력하세요"
            className="content-input"
          />
          <button onClick={handleAddContent} className="add-content-button">
            추가
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhiteBoard;
