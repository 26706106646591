import React, { useState } from 'react';
import RoomLinksModal from '../RoomLinksModal/RoomLinksModal';
import './RoomList.css';

const RoomList = ({ rooms }) => {
  const [selectedRoom, setSelectedRoom] = useState(null);

  const openModal = (room) => setSelectedRoom(room);
  const closeModal = () => setSelectedRoom(null);

  return (
    <div>
      <ul>
        {rooms.map((room) => (
          <li key={room.roomNumber} className="room-item">
            <button
              onClick={() => openModal(room)}
              className="room-title-button"
            >
              {room.name}
            </button>
            <div className='room-details'>
              <p>생성자: {room.createdBy}</p>
              <p>현재 인원: {room.participants.length}명</p>
            </div>
          </li>
        ))}
      </ul>
      {selectedRoom && (
        <RoomLinksModal
          room={selectedRoom}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default RoomList;
