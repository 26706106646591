// src/components/MainContent/ParticipantScreen/ParticipantScreen.jsx
import React, { useState, useEffect, useRef } from 'react';
import { mediaSocket as socket } from '../../../utils/socket.js';
import useMediasoupClient from '../../../hooks/useMediasoupClient';
import './ParticipantScreen.css';

const ParticipantScreen = ({ roomNumber, role }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const localVideoRef = useRef();
  const videoContainerRef = useRef();

  useEffect(() => {
    console.log('Room Number:', roomNumber);
    console.log('Role:', role);
  }, [roomNumber, role]);

  const { joinRoom, signalNewConsumerTransport } = useMediasoupClient(roomNumber, role, localVideoRef.current?.srcObject);

  useEffect(() => {
    if (!roomNumber || !role) {
      console.error('roomNumber or role is missing');
      return;
    }

    joinRoom();

    const handleNewProducer = ({ producerId }) => {
      signalNewConsumerTransport(producerId);
    };

    socket.on('new-producer', handleNewProducer);

    return () => {
      socket.off('new-producer', handleNewProducer);
      socket.disconnect();
    };
  }, [roomNumber, role, joinRoom, signalNewConsumerTransport]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    } else {
      alert('이미지 파일만 업로드할 수 있습니다.');
    }
  };

  return (
    <div className="participant-screen">
      <div className="video-chat-section">
        <p>상대방의 송출화면</p>
        <video ref={localVideoRef} autoPlay muted className="local-video" />
        <div ref={videoContainerRef} className="remote-videos"></div>
      </div>

      {imageUrl && (
        <div className="image-preview">
          <img src={imageUrl} alt="Uploaded Preview" className="media-preview" />
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="file-input"
      />
    </div>
  );
};

export default ParticipantScreen;
