import { useState, useCallback } from 'react';
import { createDevice } from '../utils/mediasoupClientHelpers.js';

const useMediasoupDevice = (signalNewConsumerTransport) => {
  const [device, setDevice] = useState(null);

  const initializeDevice = useCallback(async (rtpCapabilities) => {
    try {
      const newDevice = await createDevice(rtpCapabilities);
      setDevice(newDevice);
      console.log('Mediasoup device initialized:', newDevice);
    } catch (error) {
      console.error('Error initializing mediasoup device:', error);
    }
  }, []);

  const handleNewProducer = useCallback(
    (producerId) => {
      console.log('Handling new producer:', producerId);
      if (device) {
        signalNewConsumerTransport(producerId);
      }
    },
    [device, signalNewConsumerTransport] // 여기에 signalNewConsumerTransport 추가
  );

  return {
    device,
    initializeDevice,
    handleNewProducer,
  };
};

export default useMediasoupDevice;
