// src/components/observer/observer.jsx
import React from 'react';
import LeftSidebar from '../LeftSidebar/LeftSidebar.jsx';
import PresentationPanel from './PresentationPanel/PresentationPanel.jsx';
import ChatPanel from './ChatPanel/ChatPanel.jsx';
import RecordPanel from './RecordPanel/RecordPanel.jsx';
import RoomInfoPanel from './RoomInfoPanel/RoomInfoPanel.jsx';
import './Observer.css';

const Observer = ({ roomName }) => {
  return (
    <div className="observer">
      <LeftSidebar />
      <div className="main-content">
        <PresentationPanel roomName={roomName} />
        <div className="bottom-panels">
          <ChatPanel />
          <RecordPanel />
          <RoomInfoPanel />
        </div>
      </div>
    </div>
  );
};

export default Observer;